<template>
  <fragment>
    <div class="c-box">
      <h2 class="clearfix">
        <i class="icon-user"></i>
        <span>{{ $t('user.info') }}</span>
      </h2>
      <div class="user-info">
        <form @submit.prevent>
          <div class="info-item">
            <span class="name">{{ $t('user.name') }}</span>
            <div class="label">
              <span v-if="!editing">{{ userInfo.name }}</span>
              <input type="text" v-model="user.name" v-else />
            </div>
          </div>

          <div class="info-item">
            <span class="name">{{ $t('user.email') }}</span>
            <div class="label">
              <span v-if="!editing">{{ userInfo.email }}</span>
              <input type="text" v-model="user.email" v-else />
            </div>
          </div>
          <div class="info-item">
            <span class="name">{{ $t('user.company') }}</span>
            <div class="label">
              <span v-if="!editing">{{ userInfo.company }}</span>
              <input type="text" v-model="user.company" v-else />
            </div>
          </div>
          <div class="info-item">
            <span class="name">{{ $t('user.website') }}</span>
            <div class="label">
              <span v-if="!editing">{{ userInfo.website }}</span>
              <input type="text" v-model="user.website" v-else />
            </div>
          </div>
          <div class="info-item">
            <a href="javascript:;" class="button narrow-btn" @click="handleEdit('editing', true)" v-if="!editing">{{ $t('user.edit') }}</a>
            <template v-else>
              <a href="javascript:;" class="button narrow-btn" @click="handleUpdateUserInfo">{{ $t('common.submit') }}</a>
              <a href="javascript:;" class="button narrow-btn" @click="handleEdit('editing', false)">{{ $t('common.cancel') }}</a>
            </template>
          </div>
        </form>
      </div>
    </div>
    <div class="c-box" v-if="locale === 'zh_CN'">
      <h2 class="clearfix">
        <i class="icon-user"></i>
        <span>{{ $t('user.contact') }}</span>
        <em class="phone-check-tips" v-if="!userInfo.phoneCheck">{{ $t('user.phoneCheckTips') }}</em>
      </h2>
      <div class="user-info">
        <div class="info-item">
          <span class="name">{{ $t('user.phone') }}</span>
          <div class="label">
            <span v-if="!editPhone">{{ userInfo.phone }}</span>
            <template v-else>
              <a-select ref="select" v-model="areaCode" style="width: 140px; margin-right: 5px">
                <a-select-option :value="item.code" v-for="item in areaCodeList" :key="item.code">{{ item.code + ' ' + item.cn }}</a-select-option>
              </a-select>
              <input type="text" style="width: 230px; height: 32px" v-model="user.phone" autocomplete="off" />
            </template>
          </div>
          <div class="phone-check" v-if="!editPhone">{{ userInfo.phoneCheck ? $t('user.verified') : $t('user.unverified') }}</div>
        </div>
        <div class="info-item" v-if="editPhone">
          <span class="name">{{ $t('user.code') }}</span>
          <div class="label">
            <span v-if="!editPhone">{{ userInfo.code }}</span>
            <template v-else>
              <input style="height: 32px" type="text" v-model="user.code" autocomplete="off" />
              <button :disabled="sent" class="button button-mini" style="margin-left: 10px; vertical-align: top; height: 32px" @click="handleSendSMS">
                {{ sent ? t + 's' : $t('user.sendCode') }}
              </button>
            </template>
          </div>
        </div>
        <div class="info-item" v-if="!userInfo.phoneCheck">
          <a href="javascript:;" class="button narrow-btn" @click="handleEdit('editPhone', true)" v-if="!editPhone">{{ $t('user.verify') }}</a>
          <template v-else>
            <a href="javascript:;" class="button narrow-btn" @click="handleUpdateUserInfo">{{ $t('common.submit') }}</a>
            <a href="javascript:;" class="button narrow-btn" @click="handleEdit('editPhone', false)">{{ $t('common.cancel') }}</a>
          </template>
        </div>
      </div>
    </div>
    <div class="c-box">
      <h2 class="clearfix">
        <i class="icon-user"></i>
        <span>{{ $t('user.securityCenter') }}</span>
      </h2>
      <form @submit.prevent>
        <div class="info-item">
          <span class="name">{{ $t('user.oldPwd') }}</span>
          <div class="label">
            <input type="password" v-model="resetPwd.oldPassword" />
          </div>
        </div>
        <div class="info-item">
          <span class="name">{{ $t('user.newPwd') }}</span>
          <div class="label">
            <input type="password" v-model="resetPwd.newPassword" />
          </div>
        </div>
        <div class="info-item">
          <span class="name">{{ $t('user.confirmPwd') }}</span>
          <div class="label">
            <input type="password" v-model="resetPwd.rePassword" />
          </div>
        </div>
        <div class="info-item">
          <a href="javascript:;" class="button narrow-btn" @click="handleResetPwd">{{ $t('user.updatePwd') }}</a>
        </div>
      </form>
    </div>
  </fragment>
</template>
<script>
import schema from 'async-validator';
import Emitter from 'mixins/emitter';
import { mapGetters } from 'vuex';
import { deepCopy, isEmpty } from 'utils/util';
import { resetPassword, updateUserInfo, sendSMS } from 'services/user';
import areaCodeList from 'utils/areaCode.json';
export default {
  name: 'user',
  data() {
    const descriptor1 = {
      oldPassword: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.enterPassword'),
        },
        {
          validator: (rule, value) => value.length >= 6,
          message: this.$t('user.enterLegalPassword'),
        },
      ],
      newPassword: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.enterPassword'),
        },
        {
          validator: (rule, value) => value.length >= 6,
          message: this.$t('user.enterLegalPassword'),
        },
      ],
      rePassword: {
        type: 'string',
        required: true,
        validator: (rule, value) => value.length >= 6 && value === this.resetPwd.newPassword,
        message: this.$t('user.confirmPassword'),
      },
    };
    return {
      user: {},
      perfetInfoAble: '',
      validator1: new schema(descriptor1),
      resetPwd: {
        oldPassword: '',
        newPassword: '',
        rePassword: '',
      },
      errorTips4ResetPwd: {},
      editing: false,
      editPhone: false,
      sent: false,
      t: 0,
      areaCode: '+86',
      areaCodeList,
    };
  },
  mixins: [Emitter],
  computed: {
    ...mapGetters(['userInfo', 'locale']),
  },
  mounted() {
    if (isEmpty(this.userInfo)) {
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    handleResetPwd() {
      this.validator1
        .validate(this.resetPwd)
        .then(async () => {
          resetPassword(this.resetPwd)
            .then(async res => {
              this.$success({
                title: this.$t('user.passwordChangeSuccess'),
                onOk: () => {
                  this.$router.push('/login');
                },
              });
            })
            .catch(err => {
              this.$error({
                title: this.$t(`error['${err.response.data.errorKey}']`),
              });
            });
        })
        .catch(({ errors, fields }) => {
          this.$error({
            title: errors[0].message,
          });
          let errorTips = {};
          if (!errors) return;
          errors.forEach(error => {
            if (!errorTips[error.field]) {
              errorTips[error.field] = error.message;
            }
          });
          this.errorTips4ResetPwd = errorTips;
        });
    },
    handleEdit(target, edit) {
      this[target] = edit;
      if (edit) {
        this.user = deepCopy(this.userInfo);
      }
    },
    handleUpdateUserInfo() {
      updateUserInfo({ ...this.user, phone: this.areaCode === '+86' ? this.user.phone : this.areaCode + this.user.phone + '' })
        .then(res => {
          this.editing = false;
          this.editPhone = false;
          this.$store.dispatch('initAuth');
          this.$success({
            title: this.$t('user.userInfoUpdateSuccess'),
          });
        })
        .catch(err => {
          if (err.response.status === 403) {
            this.dispatch('account', 'error', [err]);
          }
        });
    },
    handleSendSMS() {
      this.sent = true;
      this.t = 60;
      let interval = setInterval(() => {
        if (this.t > 0) {
          this.t--;
        } else {
          this.sent = false;
          clearInterval(interval);
        }
      }, 1000);
      sendSMS({
        phone: this.areaCode === '+86' ? this.user.phone : this.areaCode + this.user.phone + '',
      }).then(res => {
        if (res.code == 0) {
          this.$success({
            title: this.$t('user.smsSendSuccess'),
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import '~assets/css/theme.less';
.c-box {
  background-color: white;
  padding: 20px;
  border-radius: 6px;
  &:not(:last-of-type) {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
    i {
      margin-right: 8px;
    }
  }
}
.info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-left: 20px;
  .name {
    width: 65px;
    text-align: justify;
    overflow: hidden;
    display: inline-block;
    color: #999;
    padding-top: 5px;
    &:after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }
  .label {
    margin-left: 30px;
    span {
      padding-top: 5px;
      padding-left: 10px;
      display: inline-block;
    }
  }
  input {
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 3px;
    width: 268px;
    &:focus {
      border-color: #bbb;
    }
  }
}
.phone-check {
  line-height: 25px;
  margin-left: 100px;
  color: #999;
}
.phone-check-tips {
  font-size: 13px;
  color: #ff9900;
  margin-left: 10px;
}
.narrow-btn {
  width: 70px;
  height: 30px;
  font-size: 13px;
  text-align: center;
  line-height: 30px;
  padding: 0;
  font-weight: 200;
}
</style>
