<template>
    <div>
        <h1>Recent Deliveries</h1>
        <a-divider/>
        <div>
            <div v-for="(delivery,key) in deliveryList" :key="delivery.id">
                <template v-if="delivery.status=='OK'">
                    <div>
                        <a-icon type="check-circle" style="margin-right: 2%"/>
                        <span @click="handleClick(key)" class="payloadClick">{{delivery.payloadUrl}}</span>
                        <span class="delivery_item_label">{{delivery.createdDate | moment("YYYY-MM-DD HH:mm:ss")}}</span>
                        <div v-if="showMoreInfo[key]" style="margin-bottom: 30px">
                            <a-tabs default-active-key="1">
                                <a-tab-pane key="1" tab="Request">
                                    <h1>Headers</h1>
                                    <div class="contentBox">
                                        <div><span class="labelSpan">Request URL:</span>{{delivery.payloadUrl}}</div>
                                        <div><span class="labelSpan">Request method:</span> POST</div>
                                        <div><span class="labelSpan">Content-Type:</span> {{delivery.reqContentType}}
                                        </div>
                                    </div>
                                    <h2>payload</h2>
                                    <div class="contentBox">
                                        <pre>{{JSON.stringify(JSON.parse(delivery.reqContent), null, 4)}}</pre>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="2" tab="Reponse">
                                    <h1>Headers</h1>
                                    <div class="contentBox">
                                        <div><span class="labelSpan">Request URL:</span>{{delivery.payloadUrl}}</div>
                                        <div><span class="labelSpan">Request method:</span> POST</div>
                                        <div><span class="labelSpan">code:</span> {{delivery.code}}</div>
                                        <div><span class="labelSpan">Content-Type:</span> {{delivery.respContentType}}
                                        </div>
                                        <div><span class="labelSpan">Content-Length:</span>
                                            {{delivery.respContentLength}}
                                        </div>
                                    </div>
                                    <h1>body</h1>
                                    <div class="contentBox">
                                        <pre>{{delivery.respContent}}</pre>
                                    </div>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div>
                        <a-icon type="close-circle" style="margin-right: 2%"/>
                        <span @click="handleClick(key)" class="payloadClick">{{delivery.payloadUrl}}</span>
                        <span class="delivery_item_label">{{delivery.createdDate | moment("YYYY-MM-DD HH:mm:ss")}}</span>
                        <div v-if="showMoreInfo[key]" style="margin-bottom: 30px">
                            <a-tabs default-active-key="1">
                                <a-tab-pane key="1" tab="Reponse">
                                    <h1>Headers</h1>
                                    <div class="contentBox">
                                        <div><span class="labelSpan">Request URL:</span>{{delivery.payloadUrl}}</div>
                                        <div><span class="labelSpan">Request method:</span> POST</div>
                                        <div v-if="delivery.code"><span class="labelSpan">code:</span> {{delivery.code}}</div>
                                        <div><span class="labelSpan">Request date:</span> {{delivery.reqDate |moment("YYYY-MM-DD HH:mm:ss")}}
                                        </div>
                                        <div v-if="delivery.respContentType"><span
                                            class="labelSpan">Content-Type:</span> {{delivery.respContentType}}
                                        </div>
                                        <div><span class="labelSpan">Message: </span>{{delivery.status}}</div>
                                        <div v-if="delivery.respContentLength"><span class="labelSpan">Content-Length:</span>
                                            {{delivery.respContentLength}}
                                        </div>
                                    </div>
                                    <h1>body</h1>
                                    <div class="contentBox">
                                        <pre>{{delivery.respContent}}</pre>
                                    </div>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </div>
                </template>
                <a-divider/>
            </div>

        </div>
    </div>
</template>

<script>
    import {fetchDelivery} from '../../services/delivery'

    export default {
        name: "delivery",
        props: {
            webHookId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                deliveryList: [],
                showMoreInfo: [],
                selectedDeliveryIndex: [],
            }
        },
        watch: {
            webHookId() {
                fetchDelivery({num: 10, webhookId: this.webHookId}).then(res => {
                    this.deliveryList = res
                    res.forEach((item, index) => {
                        this.showMoreInfo[index] = false
                    })
                })
            }
        },
        methods: {
            handleClick(index) {
                let res = !this.showMoreInfo[index]
                this.$set(this.showMoreInfo, index, res)
            }
        }
    }
</script>

<style scoped>
    .contentBox {
        border: #e1e4e8 2px solid;
        padding: 7px 12px;
        border-radius: 6px;
        margin-bottom: 20px;
        max-height: 500px;
        overflow: auto;
    }

    .labelSpan {
        font-weight: 700;
    }

    .delivery_item_label {
        float: right;
    }

    .payloadClick {
        cursor: pointer;
    }
</style>
