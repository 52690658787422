<template>
    <div class="login-container">
        <div class="form-item">
            <span>{{$t('user.webhookDetailIntroduce')}}</span>
        </div>
        <a-divider/>
        <form @submit.prevent>
            <div class="form-item">
                <label>Payload URL</label>
                <input type="text" v-model="webhook.payloadUrl" :placeholder="'https://example.com'"
                       autocomplete="off"/>
                <transition name="fade-in">
                    <p class="error-tips" style="color: red" v-if="errorTips.payloadUrl!=''">{{errorTips.payloadUrl}}</p>
                </transition>
            </div>
            <div class="form-item">
                <label>Content type</label>

                <a-select class="aselect" v-model="webhook.contentType">
                    <a-select-option value="application/json">
                        application/json
                    </a-select-option>
                    <a-select-option value="application/x-www-form-data">
                        application/x-www-form-data
                    </a-select-option>
                </a-select>
            </div>
            <a-divider/>

            <div class="form-item">
                <label><span>{{$t('user.webhookEventsTip')}}</span></label>
                <a-checkbox  :checked="checkAll" @change="onCheckAllChange">
                    {{$t('user.webhookAllEventsTip')}}
                </a-checkbox>
            </div>
            <div class="form-item">
                <a-checkbox-group
                    v-model="checkList"
                    :options="options"
                    :defaultValue="defaultCheckList"
                    @change="onChange"
                >
                    <span style="color: red" slot="label" slot-scope="value">{{value}}</span>
                </a-checkbox-group>
            </div>

            <a-divider/>
            <div class="form-item">
                <a-switch default-checked v-model="webhook.active"/>
                {{$t('user.webhookActive')}}
            </div>
            <div class="form-item">
                <button class="button long" @click="submit">{{$t('user.webhookSubmit')}}</button>
            </div>


        </form>
        <delivery :web-hook-id="this.webhook.id+''" v-if="this.$route.query.id" />
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import schema from 'async-validator';
    import {saveWebHook,getWebHook} from '../../services/webhook'
    import delivery from './delivery'


    export default {
        name: 'webhookdetail',
        components: {delivery},
        data() {
            const descriptor = {
                payloadUrl: [{
                    type: 'string',
                    required: true,
                    message: "请输入url",
                }],
            }
            return {
                customerEvent: '',
                checkAll: false,
                options :[{ label: this.$t('user.webhookPriceEvent'), value: 'PRICE_PUSH' },],
                checkList:[],
                defaultCheckList:['PRICE_PUSH'],
                selectEvent: '',
                webhook: {
                    id:'',
                    payloadUrl: '',
                    contentType: 'application/json',
                    active: true,
                    events: 'All',
                },
                validator: new schema(descriptor),
                errorTips: {
                    payloadUrl: '',
                }
            }
        },
        computed: {
            ...mapGetters(['locale']),
        },
        mounted() {
            if(this.$route.query.id){
                this.initData(this.$route.query.id)
            }
        },
        watch:{
            $route(){
                if(this.$route.query.id){
                    this.initData(this.$route.query.id)
                }else {
                    this.webhook.payloadUrl=''
                    this.webhook.id=''
                    this.webhook.contentType="application/json"
                    this.webhook.active=true
                    this.checkAll=this.checkList.length === this.options.length;
                }

            },

        },
        methods: {
            initData(id){
                getWebHook({id:id}).then(res=>{
                    this.checkList=res.events!==""?res.events.split(","):[]
                    this.webhook.payloadUrl=res.payloadUrl
                    this.webhook.contentType=res.contentType
                    this.webhook.id=id
                    this.webhook.active=res.active
                    this.checkAll=this.checkList.length === this.options.length;
                })
            },
            onChange(){
                this.checkAll = this.checkList.length === this.options.length;
            },
            onCheckAllChange(e) {
                let valueArr=[]
                this.options.forEach(item=>{
                    valueArr.push(item.value)
                })

                Object.assign(this, {
                    checkList: e.target.checked?valueArr:[],
                    checkAll: e.target.checked,
                });
            },
            submit() {
                let events='';
                this.checkList.forEach(item=>{
                    events+=(item+",")
                })
                events=events.slice(0,events.length-1)
                this.webhook.events=events
                this.validator.validate(this.webhook).then(async () => {
                    saveWebHook(this.webhook).then(async (res)=>{
                        this.$message.success("成功");
                        this.$router.push({name: 'account-name',params: {name: 'webhook'}})
                    }).catch((e)=>{
                        this.$message.error("失败，请检查信息");
                    })

                }).catch(({errors, fields}) => {
                    let errorTips = {}
                    errors.forEach(error => {
                        if (!errorTips[error.field]) {
                            errorTips[error.field] = error.message;
                        }
                    })
                    this.errorTips = errorTips;
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /*@import "../assets/css/theme.less";*/
    @import "../../assets/css/theme.less";

    .login-container {
        // border: 1px solid #f1f1f1;
        border-radius: 3px;

        h2 {
            font-size: 20px;
            padding: 20px 40px;
            text-align: center;

            i {
                margin-right: 10px;
            }
        }
    }

    .form-item {
        padding: 0 20px;
        margin-bottom: 20px;
        position: relative;

        input {
            width: 100%;
            padding: 0 10px;
            background-color: #fff;
            height: 33px;
            border: 1px solid #dcdfe6;
            border-radius: 4px;

            &:-webkit-autofill {
                box-shadow: 0 0 0px 1000px white inset !important;
            }

            &:focus {
                border-color: @primary-color;
            }
        }

        label {
            display: block;
            margin-bottom: 14px;
        }

    }

    .aselect {
        width: 100%;
        background-color: #fff;
        height: 40px;
        border-radius: 4px;

        &:-webkit-autofill {
            box-shadow: 0 0 0px 1000px white inset !important;
        }

        &:focus {
            border-color: @primary-color;
        }

        .error-tips {
            position: absolute;
            left: 40px;
            font-size: 12px;
            padding-top: 4px;
            color: red;
        }
    }

    .handle-btn {
        margin-bottom: 10px;

        &:hover, &.primary {
            color: @blue-color;
        }
    }

    .long {
        width: 100%;
        margin: 0;
    }
</style>
