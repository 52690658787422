<template>
    <fragment>
        <div class="c-box">
            <h2 class="clearfix">
                <!--                <i class="icon-user"></i>-->

                <span style="display: block;padding-left: 5px;margin-bottom: 20px;">WebHook</span>
                <a-button type="primary">
                    <router-link :to="{name: 'account-name', params: {name: 'webhookdetail'}}">
                        添加WebHook
                    </router-link>
                </a-button>
                <small style="display: block;margin-top: 2%;"><span>webhook允许在某些事件发生时通知外部服务。当指定的事件发生时，我们将向您提供的每个url发送POST请求。在我们的Webhooks指南中了解更多。</span></small>
                <a-divider/>
            </h2>
            <div class="webhookBox">
                <table class="custom-table">
                    <colgroup>
                        <col :width="90"/>
                        <col :width="80"/>

                    </colgroup>
                    <thead>
                    <tr>
                        <th>
                            <div class="cell">WebHook Url</div>
                        </th>
                        <th>
                            <div class="cell">操作</div>
                        </th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in webhookList" :key="item.id">
                        <td>
                            <div class="cell no-break">{{item.payloadUrl}}</div>
                        </td>
                        <td>

                            <a-button>
                                <router-link :to="{name: 'account-name', params: {name: 'webhookdetail',id:item.id},query:{id:item.id}}">
                                    修改
                                </router-link>
                            </a-button>
                            <a-button @click="handleDel(item.id)">删除</a-button>


                        </td>

                    </tr>
                    </tbody>
                </table>
                <a-pagination
                    size="small"
                    hideOnSinglePage

                    :defaultPageSize="15"
                    class="pull-right"
                    style="margin-top: 10px;"
                >
                </a-pagination>
            </div>
        </div>

    </fragment>
</template>
<script>
    import {delWebHook, fetchWebHook} from '../../services/webhook'
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                webhookList: [],
            };
        },
        computed: {
            ...mapGetters(['userInfo'])
        },
        mounted() {
            this.getWebHooks();
        },
        watch: {
            $route() {
                this.getWebHooks();
            }
        },
        methods: {
            getWebHooks() {

                fetchWebHook().then(res => {
                    this.webhookList = res;
                })
            },
            handleDel(id) {
                delWebHook({"id": id}).then(res => {
                    this.$message.success("成功");
                    this.webhookList=this.webhookList.filter(item=>item.id!=id)
                }).catch(e => {
                    this.$message.error("失败");
                })
            }
        }
    };
</script>
<style lang="less" scoped>
    @import '~assets/css/theme.less';

    .c-box {
        &:not(:last-of-type) {
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 18px;
            margin-bottom: 20px;

            i {
                margin-right: 8px;
            }
        }

    }

    .info-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        margin-left: 20px;

        .name {
            width: 100px;
            text-align: justify;
            overflow: hidden;
            display: inline-block;
            color: #999;
            padding-top: 5px;

            &:after {
                content: "";
                display: inline-block;
                width: 100%;
            }
        }

        .label {
            margin-left: 30px;

            span {
                padding-top: 5px;
                padding-left: 10px;
                display: inline-block;
            }
        }

        input {
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 3px;
            width: 268px;

            &:focus {
                border-color: #bbb;
            }
        }
    }

    .phone-check {
        line-height: 25px;
        margin-left: 100px;
        color: #999;
    }

    .phone-check-tips {
        font-size: 13px;
        color: #ff9900;
        margin-left: 10px;
    }
</style>
