<template>
    <div class="work-order">
        <h2 class="clearfix">
            <i class="icon-work-order"></i>
            <span>我的工单</span>
            <a href="javascript:;" class="button button-mini pull-right" @click="handleCreateWorkOrder">创建工单</a>
        </h2>
        <table class="custom-table">
            <colgroup>
                <col :width="120" />
                <col :width="200" />
                <col :width="100" />
                <col :width="150" />
                <col :width="100" />
                <col :width="150" />
            </colgroup>
            <thead>
                <tr>
                    <th>
                        <div class="cell">工单编号</div>
                    </th>
                    <th>
                        <div class="cell">工单内容</div>
                    </th>
                    <th>
                        <div class="cell">问题分类</div>
                    </th>
                    <th>
                        <div class="cell">提交时间</div>
                    </th>
                    <th>
                        <div class="cell">状态</div>
                    </th>
                    <th>
                        <div class="cell">操作</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in orderBook" :key="item._id">
                    <td>
                        <div class="cell">{{item.id}}</div>
                    </td>
                    <td>
                        <div class="cell">
                            <p class="content">{{item.content}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="cell">{{$t(`workOrder.${item.category}`)}}</div>
                    </td>
                    <td>
                        <div class="cell">{{item.createdDate | moment('YYYY-MM-DD HH:mm:ss')}}</div>
                    </td>
                    <td>
                        <div class="cell">{{$t(`workOrder.${item.status}`)}}</div>
                    </td>
                    <td>
                        <a href="javascript:;" class="button button-mini">查看</a>
                        <a href="javascript:;" class="button button-mini" @click="handleDelWo(item, index)">删除</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <a-modal title="提交工单" v-model="modalVisible" @ok="handleOk" cancelText="取消" okText="确认">
            <div class="form-item">
                <span class="label">问题类型</span>
                <a-select v-model="workOrderForm.category" style="flex: 1;">
                    <a-select-option :value="item.id" v-for="item in workOrderCategory" :key="item.id">{{item.zhName}}</a-select-option>
                </a-select>
            </div>
            <div class="form-item">
                <span class="label">问题描述</span>
                <textarea ref="textarea" class="textarea" :style="textareaStyle" v-model="workOrderForm.content"></textarea>
            </div>
        </a-modal>
    </div>
</template>
<script>
import calcTextareaHeight from 'utils/calcTextareaHeight';
import { submitWorkOrder, fetchWorkOrderCategory, fetchWorkOrders, delWorkOrder } from 'services/workOrders';
export default {
    name: 'work-order',
    data () {
        return {
            orderBook: [],
            textareaStyle: {},
            workOrderCategory: [],
            modalVisible: false,
            workOrderForm: {
                category: '',
                content: '',
            },
        }
    },
    watch: {
        'workOrderForm.content'() {
            this.$nextTick(this.resizeTextarea)
        }
    },
    mounted() {
        this.getWorkOrderList();
    },
    methods: {
        getWorkOrderList(params = {}) {
            fetchWorkOrders(params)
            .then(res => {
                this.orderBook = res.data;
            })
        },
        handleOk() {
            submitWorkOrder(this.workOrderForm)
            .then(res => {
                if(res.code === 0) {
                    this.$message.success('工单提交成功');
                    this.modalVisible = false;
                    this.getWorkOrderList();
                }else {
                    this.$message.error(res.data);
                }
            })
        },
        resizeTextarea() {
            this.textareaStyle = calcTextareaHeight(this.$refs.textarea, 5);
        },
        async handleCreateWorkOrder() {
            if(this.workOrderCategory.length === 0) {
                let res = await fetchWorkOrderCategory();
                let data = res.data;
                this.workOrderCategory = data
            }
            this.modalVisible = true;
        },
        handleDelWo(item, index) {
            this.$confirm({
                title: '确定删除该工单？',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        delWorkOrder({
                            id: item.id
                        })
                        .then(res => {
                            this.orderBook.splice(index, 1);
                            this.$message.success('删除成功');
                            resolve();
                        })      
                    });
                },
            });
        }
    }
}
</script>
<style lang="less" scoped>
@import "~assets/css/theme.less";

.work-order {
    h2 {
        font-size: 18px;
        i {
            margin-right: 8px;
        }
        margin-bottom: 10px;
    }
    
}
.content {
    line-height: 1.5;
}
.form-item {
    margin-bottom: 20px;
    display: flex;
    .label {
        width: 68px;
        text-align: right;
        line-height: 32px;
        margin-right: 10px;
        display: inline-block;
    }
    textarea {
        min-height: 127px;
        width: 100%;
        flex: 1;
        resize: none;
        // border: 1px solid #ccc;
        padding: 10px 15px;
        line-height: 1.5;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        transition: border @transition-time linear;
        &:hover, &:focus {
            border-color: @primary-color;
        }
    }
}
</style>