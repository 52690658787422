<template>
  <div class="account">
    <div class="sider-menu">
      <ul class="menu-list" v-for="(item, index) in menuList" :key="index">
        <li class="menu-item" v-for="(subItem, subIndex) in item" :class="{ active: $route.params.name === subItem.name }" :key="subIndex">
          <router-link v-if="subItem.name != 'contact'" :to="{ name: 'account-name', params: { name: subItem.name } }">
            <i :class="`icon-${subItem.icon}`"></i>
            {{ subItem.label }}
          </router-link>
        </li>
      </ul>
      <ul class="menu-list">
        <li class="menu-item">
          <a href="javacript:;" @click="logout"> <i class="icon-logout"></i>{{ $t('common.logout') }} </a>
        </li>
      </ul>
      <div class="qrcode-group">
        <span class="title"><img src="../assets/images/icons/xiaoxi.png" />{{ $t('package.contactus') }}</span>
        <div class="qrcode-item" v-if="locale === 'zh_CN'">
          <img class="qrcode-img" :src="require('../assets/images/wecom-qrcode.png')" />
          <span class="qrcode-tip wechat"><img class="tip-img" :src="require('../assets/images/icons/wechat-color.png')" /><span>联系微信客服</span></span>
        </div>
        <div class="qrcode-item">
          <a :href="locale === 'zh_CN' ? 'https://t.me/mifengcha_cs' : 'https://t.me/Blockcc_bc'" target="_blank" rel="nofollow"
            ><img id="telegram-qrcode" class="qrcode-img" :src="locale === 'zh_CN' ? require('../assets/images/telegram-qrcode.png') : require('../assets/images/telegram-qrcode-en.png')"
          /></a>
          <span class="qrcode-tip telegram"
            ><img class="tip-img" :src="require('../assets/images/icons/telegram-color.png')" /><span>{{ locale === 'zh_CN' ? '联系Telegram客服' : '@Blockcc_bc' }}</span></span
          >
        </div>
      </div>
    </div>
    <tabs v-model="$route.params.name" class="main-box">
      <tab-pane name="dashboard" lazy>
        <dashboard />
      </tab-pane>
      <tab-pane name="work-order" lazy>
        <work-order />
      </tab-pane>
      <tab-pane name="order" lazy>
        <order />
      </tab-pane>
      <tab-pane name="user" lazy>
        <user />
      </tab-pane>
      <tab-pane name="webhook" lazy>
        <webhook />
      </tab-pane>
      <tab-pane name="webhookdetail" lazy>
        <webhookdetail />
      </tab-pane>
    </tabs>
    <a-modal :closable="false" centered v-model="confirmShow" :footer="null" class="captcha-modal">
      <a-form-item class="form-item mb-0">
        <center><img src="https://mifengcha.oss-cn-beijing.aliyuncs.com/common/social/business.jpeg" /></center>
      </a-form-item>
    </a-modal>
    <user-info-modal :modalShowAble="modalShowAble"></user-info-modal>
  </div>
</template>
<script>
import dashboard from '../components/account/dashboard';
import workOrder from '../components/account/workOrder';
import order from '../components/account/order';
import contact from '../components/account/contact';
import user from '../components/account/user';
import UserInfoModal from '../components/account/userinfomodal';
import webhook from '../components/account/webhook';
import webhookdetail from '../components/account/webhookdetail';

import { mapGetters } from 'vuex';

export default {
  name: 'account',
  components: {
    dashboard,
    workOrder,
    order,
    user,
    contact,
    UserInfoModal,
    webhook,
    webhookdetail,
  },
  data() {
    return {
      // activeTab: 'dashboard'
      visible: false,
      confirmShow: false,
      modalShowAble: false,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['userInfo', 'locale']),
    menuList() {
      return [
        [
          {
            name: 'dashboard',
            label: this.$t('common.myPackage'),
            icon: 'package',
          },
        ],
        [
          /* {
                    name: 'work-order',
                    label: '我的工单',
                    icon: 'work-order'
                }, */ {
            name: 'order',
            label: this.$t('common.myOrder'),
            icon: 'order',
          },
          {
            name: 'user',
            label: this.$t('common.userManage'),
            icon: 'user-manage',
          },
        ],
      ];
    },
  },
  mounted() {
    this.$on('error', this.handleError);
  },
  methods: {
    handleError(err) {
      this.$router.push({ name: 'login' });
    },
    showModal() {
      this.visible = true;
      if (this.userInfo.company == null || this.userInfo.company == '' || this.userInfo.website == null || this.userInfo.website == '' || (this.locale === 'zh_CN' && !this.userInfo.phone)) {
        this.confirmShow = false;
        this.modalShowAble = true;
      } else {
        this.confirmShow = true;
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'index' });
    },
  },
};
</script>
<style lang="less" scoped>
@import '~assets/css/theme.less';
.account {
  width: @container;
  margin: 30px auto 0 auto;
  display: flex;
}
.sider-menu {
  width: 200px;
}
@border: 1px solid #ddd;
.main-box {
  // background: #F1F1F5;
  // border: @border;
  flex: 1;
  margin-left: 20px;
  // padding: 20px;
  // margin-left: -1px;
}
.menu-list {
  margin-bottom: 20px;
  border: @border;
  border-radius: 4px;
  position: relative;
  z-index: 22;
  overflow: hidden;
  background-color: white;
  .menu-item {
    transition: background 0.15s linear;
    // margin-right: -1px;
    // border-right: @border;
    &:not(:last-of-type) {
      border-bottom: @border;
    }
    &:hover {
      background-color: lighten(#ddd, 10%);
    }
    &.active {
      background: #f7f7f7;
      font-weight: bold;
      a {
        color: #fb6a2e !important;
      }
    }
    a {
      padding: 15px 20px;
      display: block;
      cursor: pointer;

      i {
        margin-right: 10px;
      }
      &:hover {
        color: #444;
      }
    }
  }
}
.qrcode-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 15px 0;
  border: @border;
  border-radius: 4px;
  .title {
    font-size: 14px;
    margin-bottom: 15px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin-right: 9px;
    }
  }
  .qrcode-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrcode-img {
      width: 125px;
      height: 125px;
    }
    .qrcode-tip {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      // &.telegram{
      //     font-size: 12px;
      // }
      .tip-img {
        height: 16px;
        width: 16px;
        margin-right: 5px;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}
</style>
