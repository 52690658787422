<template>
  <div class="order">
    <h2 class="clearfix">
      <i class="icon-order"></i>
      <span>{{ $t('common.myOrder') }}</span>
    </h2>
    <table class="custom-table">
      <colgroup>
        <col :width="90" />
        <col :width="80" />
        <col :width="70" />
        <col :width="90" />
        <col :width="90" />
        <col :width="100" />
        <col :width="80" />
        <col :width="80" />
        <col :width="90" />
        <col :width="80" />
      </colgroup>
      <thead>
        <tr>
          <th>
            <div class="cell">{{ $t('order.no') }}</div>
          </th>
          <th>
            <div class="cell">{{ $t('order.product') }}</div>
          </th>
          <th>
            <div class="cell">{{ $t('order.duration') }}</div>
          </th>
          <th>
            <div class="cell">{{ $t('order.type') }}</div>
          </th>
          <th>
            <div class="cell">{{ $t('order.createTime') }}</div>
          </th>
          <th>
            <div class="cell" v-html="$t('order.payTime')"></div>
          </th>
          <th>
            <div class="cell">{{ $t('order.status') }}</div>
          </th>
          <th>
            <div class="cell">{{ $t('order.originalPrice') }}</div>
          </th>
          <th>
            <div class="cell">{{ $t('order.payable') }}</div>
          </th>
          <th>
            <div class="cell">{{ $t('order.operate') }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in orders.list" :key="item.orderId" v-if="item.orderId">
          <td>
            <div class="cell no-break">{{ item.orderId }}</div>
          </td>
          <td>
            <div class="cell">
              <p class="content">{{ $t(`package.${item.orderVersion}`) }}</p>
            </div>
          </td>
          <td>
            <div class="cell">{{ item.duration | formatZh(locale) }}</div>
          </td>
          <td>
            <div class="cell">{{ item.orderStatus ? $t(`order.${item.orderStatus}`) : '' }}</div>
          </td>
          <td>
            <div class="cell">
              <span class="lh">{{ item.createdDate | moment('YYYY-MM-DD') }}<br />{{ item.createdDate | moment('HH:mm:ss') }}</span>
            </div>
          </td>
          <td>
            <div class="cell">
              <template v-if="item.openingDate">
                <span class="lh">{{ item.openingDate | moment('YYYY-MM-DD') }}<br />{{ item.openingDate | moment('HH:mm:ss') }}</span>
              </template>
            </div>
          </td>
          <td>
            <div class="cell" :class="{ 'text-gray': item.status === 'CANCEL', pending: item.status === 'PENDING', confirm: item.status === 'CONFIRMED' }">{{ $t(`order.${item.status}`) }}</div>
          </td>
          <td>
            <div class="cell">
              <!-- <Numberic :data="item.originalMoney" prefix="¥" :fold="false"/> -->
              <div class="cell">{{ returnFloat(item.originalMoney) }} {{ item.originalCurrency }}</div>
            </div>
          </td>
          <td>
            <!-- <div class="cell">{{item.orderMoney}} {{item.currency}}</div> -->
            <div class="cell">{{ returnFloat(item.orderMoney) }} {{ item.originalCurrency }}</div>
          </td>
          <td>
            <div class="cell">
              <button class="link" v-if="item.status === 'PENDING' || item.status === 'INADEQUATE'" @click="handlePay(item)">
                {{ $t('order.pay') }}
                <!-- 联系客服 -->
              </button>
              <button class="link border-left" @click="handleOrderCancel(item)" v-if="item.status == 'PENDING'">
                {{ $t('order.void') }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <a-pagination :current="orderParams.page + 1" :total="orders.total" hideOnSinglePage size="small" @change="handlePageChange" :defaultPageSize="15" class="pull-right" style="margin-top: 10px">
    </a-pagination>
    <a-modal v-model="QRCodeVisible" :footer="null" class="qrcode-dialog" @cancel="reload()" :width="400">
      <div class="qrcode-content">
        <img src="../../assets/images/wechatpay-logo.png" class="wechat-log" />
        <img :src="QRCodeImg" class="qrcode-img" />
        <img src="../../assets/images/wechatpay-tips.png" class="wechat-tips" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { createWeChatPayUrl, createPlisioPayUrl, createAliPayUrl, fetchOrders, orderCancel } from 'services/orders';
import Emitter from 'mixins/emitter';

const LANG = {
  zh_CN: {
    year: num => `${num}年`,
    month: num => `${num}个月`,
  },
  en_US: {
    year: num => `${num}Y`,
    month: num => `${num}M`,
  },
};
function returnFloat(value) {
  var value = Math.round(parseFloat(value) * 100) / 100;
  var xsd = value.toString().split('.');
  if (xsd.length == 1) {
    value = value.toString() + '.00';
    return value;
  }
  if (xsd.length > 1) {
    if (xsd[1].length < 2) {
      value = value.toString() + '0';
    }
    return value;
  }
}
export default {
  name: 'work-order',
  mixins: [Emitter],
  data() {
    return {
      orders: {
        list: [],
      },
      orderParams: {
        page: 0,
      },
      QRCodeImg: '',
      QRCodeVisible: false,
      returnFloat,
    };
  },
  filters: {
    formatZh(value, locale) {
      if (value >= 12) {
        return LANG[locale].year(value / 12);
      } else {
        return LANG[locale].month(value);
      }
    },
  },
  computed: {
    ...mapGetters(['locale']),
  },
  mounted() {
    this.getOrders(this.orderParams);
  },
  methods: {
    getOrders(params = {}) {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      fetchOrders(params)
        .then(res => {
          let data = res.data;
          data.total = data.totalPages * 15;
          this.orders = data;
        })
        .catch(err => {
          this.dispatch('account', 'error', [err]);
        });
    },
    handlePageChange(page) {
      this.orderParams.page = page - 1;
      this.getOrders(this.orderParams);
    },
    handleOrderCancel(item) {
      this.$confirm({
        title: this.$t('order.confirmVoidOrder'),
        okText: this.$t('common.confirm'),
        cancelText: this.$t('common.cancel'),
        onOk: () => {
          return new Promise((resolve, reject) => {
            orderCancel({
              id: item.orderId,
            }).then(res => {
              if (res.code === 0) {
                this.$message.success(this.$t('order.orderCanceled'));
                item.status = 'CANCEL';
              }
              resolve();
            });
          });
        },
      });
    },
    handlePay(orderItem) {
      if (orderItem.originalCurrency === 'CNY') {
        this.handleWeChatPay({
          currency: orderItem.originalCurrency,
          amount: orderItem.orderMoney,
          orderId: orderItem.orderId,
        });
      } else {
        this.handlePlisioPay(orderItem);
      }
      // window.open('https://t.me/mifengcha_cs','_blank')
    },
    // handleAliPay(params) {
    //   createAliPayUrl(params)
    //     .then(res => {
    //       if (res.code == 0) {
    //         let divForm = document.getElementsByTagName('divform');
    //         if (divForm.length) {
    //           document.body.removeChild(divForm[0]);
    //         }
    //         const div = document.createElement('divform');
    //         div.innerHTML = res.data; // data就是接口返回的form 表单字符串
    //         document.body.appendChild(div);
    //         document.forms[0].setAttribute('target', '_blank'); // 新开窗口跳转
    //         document.forms[0].submit();
    //       }
    //     })
    //     .catch(err => {
    //       this.$error({
    //         title: this.$t(`error['${err.response.data.errorKey}']`),
    //       });
    //     });
    // },
    handleWeChatPay(params) {
      createWeChatPayUrl(params)
        .then(res => {
          if (res.code === 0) {
            this.QRCodeImg = 'data:image/jpeg;base64,' + res.data;
            this.QRCodeVisible = true;
          } else {
            this.$error({
              title: '微信支付二维码生成失败',
            });
          }
        })
        .catch(err => {
          this.$error({
            title: '微信支付二维码生成失败',
          });
        });
    },
    handlePlisioPay(params) {
      // createPlisioPayUrl(params)
      //   .then(res => {
      //     if (res.code === 0 && res.data) {
      //       window.open(res.data, '_blank');
      //     } else {
      //       this.$error({
      //         title: 'Pay Error!',
      //       });
      //     }
      //   })
      //   .catch(err => {
      //     this.$error({
      //       title: 'Pay Error!',
      //     });
      //   });
      if (params.externalUrl) {
        window.open(params.externalUrl, '_blank');
      } else {
        this.$error({
          title: 'Pay Error!',
        });
      }
    },
    reload() {
      location.reload();
    },
  },
};
</script>
<style lang="less" scoped>
@import '~assets/css/theme.less';

.order {
  background-color: white;
  padding: 20px;
  min-height: 900px;
  border-radius: 6px;
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
    i {
      margin-right: 8px;
    }
  }
}
.content {
  line-height: 1.5;
}
.custom-table {
  th,
  td {
    font-size: 12px;
    button {
      font-size: 12px;
    }
  }
  th {
    background-color: #e6e6e6;
  }
  .lh {
    line-height: 1.5;
  }
}

.border-left {
  border-left: solid 1px gray;
  padding-left: 5px;
}

.pending {
  color: #f15533;
}

.confirm {
  color: #35b34a;
}
.order {
  font-weight: 200 !important;
}
.qrcode-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .wechat-log {
    width: 128px;
  }
  .qrcode-img {
    width: 250px;
  }
  .wechat-tips {
    width: 200px;
  }
}
</style>
