import $http from '../utils/request';

export const fetchWebHook = async params => await $http.get('webHooks',{
    params
});

export const saveWebHook = async params => await $http.post('save', params)

export const delWebHook = async params => await $http.get('del',{params})

export const getWebHook = async params => await $http.get('webhook',{params})
