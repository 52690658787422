import $http from '../utils/request';

export const fetchWorkOrders = async params => await $http.get('tickets', {
    params
});

export const fetchWorkOrderCategory = async () => await$http.get('ticket/category');

export const submitWorkOrder = async params => await$http.post('ticket/submit', params);

export const delWorkOrder = async params => await $http.post('ticket/delete', params);

export const closeWorkOrder = async params => await $http.post('ticket/close', params);
