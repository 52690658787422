<template>
  <div class="dashboard">
    <div class="c-b data-statistical">
      <p class="c-b-name">
        <i class="icon-package"></i>
        <span>{{ $t('common.dataStatistics') }}</span>
      </p>
      <div class="data-content">
        <div class="data-item">
          <div class="data-top">
            <span class="data-number">{{ userInfo.yesterdayUsageCount }}</span>
            <span class="data-unit">{{ $t('package.times') }}</span>
          </div>
          <div class="data-name">{{ $t('package.yesterday_times') }}</div>
        </div>
        <div class="data-item">
          <div class="data-top">
            <span class="data-number">{{ userInfo.usageCount }}</span>
            <span class="data-unit">{{ $t('package.times') }}</span>
          </div>
          <div class="data-name">{{ $t('package.useage_times') }}</div>
        </div>
        <div class="data-item">
          <div class="data-top">
            <span class="data-number">{{ userInfo.totalCount - userInfo.usageCount }}</span>
            <span class="data-unit">{{ $t('package.times') }}</span>
          </div>
          <div class="data-name">{{ $t('package.month_times') }}</div>
        </div>
        <div class="data-item">
          <div class="data-top">
            <span class="data-number">{{ userInfo.validTime }}</span>
            <span class="data-unit">{{ $t('package.days') }}</span>
          </div>
          <div class="data-name">{{ $t('package.package_days') }}</div>
        </div>
      </div>
    </div>
    <div class="c-b">
      <p class="c-b-name">
        <i class="icon-package"></i>
        <span>{{ $t('common.myPackage') }}</span>
        <span v-if="showAble"
          ><small
            ><router-link to="/account/user" style="color: red">{{ perfectInfoTips }}</router-link></small
          ></span
        >
      </p>
      <ul class="package" v-if="userInfo.pricePackage != 'NONE'">
        <li>
          <span class="name">{{ $t('package.type') }}</span>
          <div class="label">
            {{ userInfo.pricePackage && $t(`package.${userInfo.pricePackage}`) }}
            <a class="button button-mini" @click="handleUpgrade">{{ $t('package.upgrade4MorePrivileges') }}</a>
          </div>
        </li>
        <li>
          <span class="name">{{ $t('package.expireDate') }}</span>
          <div class="label">
            {{ userInfo.expireTime | moment('YYYY-MM-DD HH:mm:ss') }}
            <router-link :to="{ name: 'order', query: { package: userInfo.pricePackage, duration: userInfo.duration || 1 } }" class="button button-mini">{{ $t('package.renew') }}</router-link>
          </div>
        </li>
        <li>
          <span class="name">{{ $t('package.apiUsageRestrictions') }}</span>
          <span class="label" style="color: #888">
            <!-- <i18n path="package.callsPerMonth">
              <em>{{ userInfo.totalCount }}</em>
            </i18n>
            <i18n path="package.used">
              <em>{{ userInfo.usageCount || 0 }}</em>
              <em>{{ userInfo.totalCount }}</em>
            </i18n> -->
            <em>{{ userInfo.totalCount }} {{ $t('package.callsPerMonth') }}</em>
          </span>
        </li>
        <!-- <li>
          <span class="name">预警手机号</span>
          <div class="label">
            {{ userInfo.phone }}
            <a href="javascript:;" class="button button-mini">绑定</a>
          </div>
        </li> -->
      </ul>
      <router-link :to="{ name: 'order', query: { package: 'ENTERPRISE', duration: 3 } }" class="button" v-else>{{ $t('package.open') }}</router-link>
    </div>
    <div class="c-b">
      <p class="c-b-name">
        <i class="icon-key"></i>
        <span>{{ $t('package.yourApiKey') }}</span>
      </p>
      <div class="input-form">
        <template v-if="userInfo.pricePackage != 'NONE'">
          <input type="text" v-model="userInfo.apiKey" disabled />
          <a-tooltip :title="$t('package.copySuccuess')" :visible="copySuccess">
            <a href="javascript:;" class="link" v-clipboard:copy="userInfo.apiKey" v-clipboard:success="onCopy" v-clipboard:error="onError">{{ $t('package.copy') }}</a>
          </a-tooltip>
          <a-tooltip :title="$t('package.changeSuccuess')" :visible="changeSuccess">
            <a class="link" href="javascript:;" @click="handleResetApiKey">{{ $t('package.change') }}</a>
          </a-tooltip>
        </template>
        <template v-else>
          <a-input class="none-input" type="text" v-model="noneKey" disabled />
        </template>
      </div>
    </div>
    <div class="c-b">
      <p class="c-b-name">
        <i class="icon-doc"></i>
        <span>{{ $t('package.howToUse') }}</span>
        <doc-url class="link">{{ $t('package.viewDoc') }}</doc-url>
      </p>
      <div class="input-form">
        <input type="text" v-model="url" disabled />
        <a :href="url" target="_blank" class="link">{{ $t('package.run') }}</a>
      </div>
    </div>
    <div class="c-b" v-if="userInfo.isOpen">
      <p class="c-b-name">
        <i class="icon-statistics"></i>
        <span>{{ $t('package.apiUsage') }}</span>
      </p>
      <div class="api-info">
        <i18n path="package.useBalance" tag="span" style="color: #999">
          <em style="color: #222">{{ this.userInfo.usageCount || 0 }}</em>
          <em style="color: #222">{{ this.userInfo.totalCount }}</em>
        </i18n>
        <!-- <span v-if="userInfo.pricePackage">
          套餐到期时间: {{ userInfo.expireTime | moment('YYYY-MM-DD HH:mm:ss') }}
          <router-link :to="{ name: 'order', query: { package: userInfo.pricePackage, duration: userInfo.duration } }" class="link">续费</router-link>
        </span> -->
        <span class="pull-right" v-if="userInfo.usageCount / userInfo.totalCount >= 1">
          <em class="text-red">{{ $t('package.exhausted') }}</em>
          <a class="link" @click="handleUpgrade">{{ $t('package.upgrade') }}</a>
        </span>
      </div>
      <div class="progress">
        <div class="progress-track">
          <div class="progress-bg" :style="{ width: usagePercent + '%' }"></div>
        </div>
        <div class="text" :class="{ 'font-black': usagePercent <= 6 }" v-if="userInfo.pricePackage != 'NONE'">{{ usagePercent }}%</div>
      </div>
    </div>
    <user-info-modal :modalShowAble="modalShowAble" @userUpdated="" @changeModalData="changeModalData"></user-info-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { fetchUsageHistory } from 'services/index';
import { updateApiKey } from 'services/user';
import Emitter from 'mixins/emitter';
import docUrl from 'components/doc';
import UserInfoModal from './userinfomodal';
import Chart from 'chart.js';

Chart.plugins.register({
  afterDraw: function (chart) {
    if (chart.data.datasets[0].data.every(item => item === 0)) {
      let ctx = chart.chart.ctx;
      let width = chart.chart.width;
      let height = chart.chart.height;

      chart.clear();
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('暂无使用数据', width / 2, height / 2);
      ctx.restore();
    }
  },
});
export default {
  name: 'dashboard',
  components: {
    docUrl,
    UserInfoModal,
  },
  data() {
    return {
      apiKey: 'SBXKAGXBHEF9RWYSMQTOEQIIWJGK44ZB',
      copySuccess: false,
      changeSuccess: false,
      packageMap: {},
      perfectInfoTips: this.$t('user.perfectInfo'),
      historyData: [],
      modalShowAble: false,
      showAble: false,
    };
  },
  mixins: [Emitter],
  computed: {
    ...mapGetters(['userInfo', 'packages', 'locale', 'firstShowModal']),
    url() {
      return `http://data.mifengcha.com/api/v3/markets?api_key=${this.userInfo.apiKey || ''}`;
    },
    usagePercent() {
      let usagePercent = (this.userInfo.totalCount ? this.userInfo.usageCount / this.userInfo.totalCount : 0) * 100;
      return Number(usagePercent.toFixed(2));
    },
    noneKey() {
      return this.$t('user.getKeyPlaceholder');
    },
  },
  watch: {
    $route(route) {
      if (route.params.name === 'dashboard') {
        this.$store.dispatch('initAuth');
        this.initUsageHistory();
      }
    },
    userInfo(newVal, oldVal) {
      if ((newVal.company == '' || newVal.company == null) && (newVal.website == '' || newVal.website == null)) {
        this.showAble = true;
      } else {
        this.showAble = false;
      }
    },
  },
  mounted() {
    this.showModal();
    this.initUsageHistory();
    this.packages.forEach((item, index) => {
      this.packageMap[item.name] = item.level;
    });
  },
  methods: {
    onCopy(e) {
      this.copySuccess = true;
      setTimeout(() => {
        this.copySuccess = false;
      }, 3800);
    },
    onError(e) {
      console.log(e);
    },
    async showModal() {
      var perfetInfo =
        this.userInfo.name == null ||
        this.userInfo.name == '' ||
        this.userInfo.company == null ||
        this.userInfo.company == '' ||
        this.userInfo.website == null ||
        this.userInfo.website == '' ||
        (this.locale === 'zh_CN' && !this.userInfo.phone);
      if (this.firstShowModal && perfetInfo) {
        this.modalShowAble = true;
        this.showAble = true;
        await this.$store.dispatch('setFirstShowModal', false);
      } else if (this.firstShowModal == false && perfetInfo == true) {
        this.modalShowAble = false;
        this.showAble = true;
      }
    },
    initUsageHistory() {
      // if(!this.userInfo.isOpen) return;
      fetchUsageHistory()
        .then(res => {
          if (this.userInfo.pricePackage && this.userInfo.isOpen) {
            let data = res.data.chart;
            this.fixChartData(data);
          }
        })
        .catch(err => {
          console.log(err);
          this.dispatch('account', 'error', [err]);
        });
    },
    fixChartData(chartData) {
      chartData.sort((a, b) => a[0] - b[0]);
      const TARGET_POINTS = 30;
      const ONE_DAY = 86400000;
      if (chartData.length >= TARGET_POINTS) {
        this.initChart(chartData);
        return;
      }
      let newestData = [Date.now(), 0];
      if (chartData.length > 0) {
        let lastPointData = chartData[chartData.length - 1];
        if (this.startTime(lastPointData[0]) === this.startTime(newestData[0])) {
          newestData = lastPointData;
        }
      }

      let newChartData = [];
      let existDates = {};
      chartData.forEach(item => {
        item[0] = this.startTime(item[0]);
        existDates[item[0]] = item[1];
      });
      let newestDate = newestData[0];
      let newestDateStartTime = this.startTime(newestDate);
      for (let i = TARGET_POINTS; i > 0; i--) {
        let currentDate = newestDateStartTime - (TARGET_POINTS - i) * ONE_DAY;
        if (existDates[currentDate]) {
          newChartData.unshift([currentDate, existDates[currentDate]]);
        } else {
          newChartData.unshift([currentDate, 0]);
        }
      }
      this.initChart(newChartData);
    },
    startTime(time) {
      const nowTimeDate = new Date(time);
      return nowTimeDate.setHours(0, 0, 0, 0);
    },
    changeModalData(data) {
      if (data === 'false') {
        this.modalShowAble = false;
      } else {
        this.modalShowAble = true;
      }
    },
    handleUpgrade() {
      let upgradePackageName = '';
      let currentLevel = this.packageMap[this.userInfo.pricePackage];
      for (let [name, level] of Object.entries(this.packageMap)) {
        if (level > currentLevel || level === 5) {
          upgradePackageName = name;
          break;
        }
      }
      this.$router.push({
        name: 'order',
        query: { package: upgradePackageName, duration: this.userInfo.duration || 3 },
      });
    },
    handleResetApiKey() {
      this.$store.dispatch('initAuth');
      updateApiKey()
        .then(res => {
          this.changeSuccess = true;
          this.$store.dispatch('initAuth');
          setTimeout(() => {
            this.changeSuccess = false;
          }, 3800);
        })
        .catch(e => {
          this.changeSuccess = false;
        });
    },
    initChart(chartData = []) {
      return;
      //   let ctx = this.$refs.chart;
      //   let labels = chartData.map(item => item[0]);
      //   let data = chartData.map(item => item[1]);
      //   let maximum = String(Math.max(...data));
      //   let stepSize = (~~maximum.substr(0, 1) + 1 + ''.padEnd(maximum.length - 1, 0)) / 2;
      //   new Chart(ctx, {
      //     type: 'line',
      //     data: {
      //       labels,
      //       datasets: [
      //         {
      //           label: '# of Votes',
      //           data,
      //           backgroundColor: 'rgba(0, 0, 0, 0.2)',
      //           borderColor: 'rgba(0, 0, 0, 0.3)',
      //           borderWidth: 1,
      //           pointRadius: 1,
      //           lineTension: 0,
      //         },
      //       ],
      //     },
      //     options: {
      //       tooltips: {
      //         custom: function (tooltip) {
      //           if (!tooltip) return;
      //           tooltip.displayColors = false;
      //         },
      //         callbacks: {
      //           title: tooltipItem => {
      //             let index = tooltipItem[0].index;
      //             return moment(labels[index], 'YYYY-MM-DD', this.locale);
      //           },
      //           label: tooltipItem => {
      //             let index = tooltipItem.index;
      //             return `使用次数: ${data[index]}`;
      //           },
      //           labelColor: (tooltipItem, chart) => {
      //             return {
      //               borderColor: 'transparent',
      //               backgroundColor: 'transparent',
      //             };
      //           },
      //         },
      //       },
      //       legend: {
      //         display: false,
      //       },
      //       scales: {
      //         yAxes: [
      //           {
      //             ticks: {
      //               // beginAtZero: true,
      //               stepSize,
      //             },
      //             gridLines: {
      //               lineWidth: 0,
      //             },
      //           },
      //         ],
      //         xAxes: [
      //           {
      //             type: 'time',
      //             time: {
      //               unit: 'day',
      //               unitStepSize: 5,
      //               displayFormats: {
      //                 quarter: 'MMM D',
      //               },
      //             },
      //             gridLines: {
      //               lineWidth: 0,
      //             },
      //             ticks: {
      //               callback: value => moment(value, 'MM-DD', this.locale),
      //             },
      //           },
      //         ],
      //       },
      //     },
      //   });
    },
  },
};
</script>
<style lang="less" scoped>
@import '~assets/css/theme.less';
.chart {
  width: 100%;
  height: 300px;
}
.dashboard {
  margin-bottom: 20px;
  // padding: 0 20px;
  background-color: #f1f1f5;
}
.c-b {
  background-color: white;
  padding: 27px 40px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 6px;
  &:not(:last-of-type) {
    border-bottom: 1px solid #f1f1f1;
  }
  .c-b-name {
    font-size: 18px;
    margin-bottom: 20px;
    i {
      margin-right: 8px;
    }
    a {
      margin-left: 10px;
    }
  }
}
.link {
  font-size: 14px;
}
.package {
  li {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-bottom: 25px;
    .name {
      width: 100px;
      text-align: right;
      color: #999;
    }
    .label {
      padding-left: 50px;
      em {
        color: @dark-color;
      }
    }
    .button {
      margin-left: 15px;
    }
  }
}
.input-form {
  display: flex;
  align-items: center;
  input {
    width: 400px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 38px;
    padding: 0 10px;
  }
  .link {
    margin-left: 10px;
  }
  .none-input {
    background: url(./../../assets/images/warning.png) no-repeat 5px center;
    background-size: 16px 16px;
    background-position: 10px;
    text-indent: 22px;
  }
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.06);
  }
}
.api-info {
  margin-bottom: 15px;
  span {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  .link {
    margin-left: 5px;
    font-size: 12px;
  }
}
.progress {
  width: 100%;
  position: relative;
  .progress-track {
    background-color: #f5f5f5;
    vertical-align: middle;
    border-radius: 12px;
    .progress-bg {
      height: 24px;
      border-radius: 12px;
      background-color: #ffa800;
    }
  }
  .text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 12px;
    left: 10px;
    &.font-black {
      color: #333;
    }
  }
}
.data-statistical {
  display: flex;
  flex-direction: column;
  .data-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .data-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f8;
    width: 220px;
    height: 80px;
    .data-top {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .data-number {
        font-size: 24px;
        font-family: Arial;
        font-weight: 400;
        color: #1e2133;
      }
      .data-unit {
        margin-bottom: 3px;
        margin-left: 5px;
        font-size: 12px;
        font-family: Arial;
        color: #1e2133;
      }
    }
    .data-name {
      margin-top: 10px;
      font-size: 8px;
      font-family: Microsoft YaHei;
      color: #53555e;
      opacity: 0.7;
    }
  }
}
</style>
